'use strict';

angular.module('cpformplastApp')
  .directive('piechart', () => ({
    templateUrl: 'components/pie-chart/pie-chart.html',
    restrict: 'E',
    controller: 'PieChartController',
    controllerAs: 'piechart',
    scope: {
      size: '=size',
      portions: '=portions'
    }
  }));
